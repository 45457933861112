import axios from 'axios';

let qs = require('qs');
const sprintf = require('sprintf-js').sprintf;

export default class CRMClient {
  constructor(type) {
    this.type = type;
  }

  async retrieve({pathParameters, queryStrings} = {}) {
    let url = `${process.env.VUE_APP_CRM_V1_BASE_URL}/${this.type}/%(entryId)s/forms/%(formId)s`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'get',
      params: {stage: "staging", ...queryStrings},  // Remove stage
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'})
      },
    });
  }

  async all({pathParameters, queryStrings} = {}) {
    let url = `${process.env.VUE_APP_CRM_V1_BASE_URL}/${this.type}/%(entryId)s/forms`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'get',
      params: {stage: "staging", ...queryStrings},  // Remove stage
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'})
      },
    });
  }

  async requestReportGeneration({pathParameters, data} = {}) {
    let url = `${process.env.VUE_APP_CRM_V1_BASE_URL}/${this.type}/%(entryId)s/report`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'post',
      data: data,
    });
  }
}
